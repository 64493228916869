<script>
export default {
  methods: {
    copyUUIDToClipboard (uuid) {
      navigator.clipboard.writeText(uuid)
        .then(() => {
          this.$buefy.notification.open({
            message: 'Identifiant unique copié dans le presse-papiers.',
            type: 'is-success',
            position: 'is-bottom',
            duration: 5000
          })
        })
    }
  }
}
</script>

<template>
  <div class="space-y-2 py-4 whitespace-normal">
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">UUID</div>
      <div class="w-4/5 select-all" @click="copyUUIDToClipboard(params.data.uuid)">{{ params.data.uuid }}</div>
    </div>
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Question</div>
      <div class="w-4/5">{{ params.data.title }}</div>
    </div>
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Réponse</div>
      <div class="w-4/5" v-html="params.data.response"></div>
    </div>
    <div class="flex">
      <div class="w-1/6 text-right font-bold mr-2">Mots-clés</div>
      <div class="w-4/5">
        {{ params.data.keywords.join(', ') }}
      </div>
    </div>
  </div>
</template>
