<template>
  <div>
    <PageHeader
      :title="`FAQ > Questions NosDroits`"
      :description="`Choix des questions issues de la base commune NosDroits`"
    />

    <div v-if="['admin'].some(role => roles.includes(role))">
      <b-button type="is-success is-light" @click="confirmSelectAll()">
        Tout activer
      </b-button>
      <b-button type="is-danger is-light" @click="confirmUnselectAll()">
        Tout désactiver
      </b-button>
    </div>

    <QuestionsSubmenu />

    <PageContent>
      <MVPTable :gridData="gridData" :options="gridOptions" />
    </PageContent>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import BooleanCellRenderer from '@/components/agGrid/BooleanCellRenderer.vue'
import BooleanFilterCellRenderer from '@/components/agGrid/BooleanFilterCellRenderer.vue'
import QuestionsSubmenu from './QuestionsSubmenu.vue'
import ListRowDetail from './ListRowDetail.vue'

export default {
  name: 'app-questions-universals',
  components: {
    PageHeader,
    QuestionsSubmenu,
    PageContent,
    BooleanCellRenderer, //eslint-disable-line
    BooleanFilterCellRenderer, //eslint-disable-line
    ListRowDetail, //eslint-disable-line
    MVPTable //eslint-disable-line
  },
  computed: {
    ...mapGetters([
      'roles',
      'user'
    ])
  },
  data () {
    return {
      loading: false,
      questions: [],
      all: [],
      domain: this.$store.getters.domain,
      searchInput: '',
      tableQuestions: [],
      tableData: {
        question: null
      },
      gridApi: null,
      gridData: null,
      gridOptions: {
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        enableCellChangeFlash: true,
        columnDefs: [
          {
            headerName: 'Type',
            cellRenderer: 'agGroupCellRenderer',
            field: 'type.name',
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
              applyMiniFilterWhileTyping: true
            }
          },
          {
            headerName: 'Catégorie',
            field: 'category.label',
            filter: 'agSetColumnFilter',
            sortable: true
          },
          {
            headerName: 'Question',
            field: 'title',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Activée',
            headerClass: 'ag-center-aligned-header',
            field: 'is_selected',
            sortable: true,
            cellRenderer: 'BooleanCellRenderer',
            cellRendererParams: {
              onClick: (params) => this.toggleQuestion(params)
            },
            width: 100,
            cellStyle: {
              'textAlign': 'center'
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              cellRenderer: 'BooleanFilterCellRenderer',
              buttons: ['reset']
            }
          }
        ],
        defaultColDef: {
          resizable: true,
          floatingFilter: true
        }
      }
    }
  },
  created () {
    this.loadQuestions()
  },
  methods: {
    async search () {
      if (this.searchInput.length > 3) {
        const questions = await http.post('/elasticsearch/search-universals', { query: this.searchInput })
        this.tableData.questions = questions.data
      } else {
        this.tableData.questions = this.questions
      }
    },
    goToQuestion (question) {
      this.$router.push({ path: `/app/question/${question.uuid}` })
    },
    loadQuestions () {
      this.loading = true
      http.get('/faq/universal').then(res => {
        this.loading = false
        this.gridData = res.data
        this.questions = res.data
        this.tableData.questions = this.questions
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des questions',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },

    toggleQuestion (question) {
      if (question.is_selected) {
        http.delete(`/faq/universal/${question.uuid}`).then(res => {
          question.is_selected = !question.is_selected
        }).catch(() => {
          question.is_selected = 1
        })
      }
      if (!question.is_selected) {
        http.put(`/faq/universal/${question.uuid}`).then(res => {
          question.is_selected = !question.is_selected
        }).catch(() => {
          question.is_selected = 0
        })
      }
    },

    confirmSelectAll () {
      this.$buefy.dialog.confirm({
        message: 'Souhaitez-vous activer toutes les questions ?',
        onConfirm: () => this.selectAll()
      })
    },
    selectAll () {
      http.put(`/faq/universal`).then(res => {
        this.tableData.questions = this.questions.map(question => {
          question.is_selected = true
          return question
        })
        console.log('ici', this.tableData.questions)
      }).catch(() => {
        //
      })
    },
    confirmUnselectAll () {
      this.$buefy.dialog.confirm({
        message: 'Souhaitez-vous désactiver toutes les questions ?',
        onConfirm: () => this.unselectAll()
      })
    },
    unselectAll () {
      http.delete(`/faq/universal`).then(res => {
        this.questions = this.questions.map(question => {
          question.is_selected = false
          return question
        })
      }).catch(() => {
        //
      })
    }
  }
}
</script>
